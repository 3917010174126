import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

export default function Geoguessr() {
  const { width, height } = useWindowSize();
  const [confettiDimensions, setConfettiDimensions] = useState({
    width,
    height
  });

  useEffect(() => {
    setConfettiDimensions({ width, height });
  }, [width, height]);

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full">
        <Confetti
          width={confettiDimensions.width}
          height={confettiDimensions.height}
        />
      </div>
      <div className="w-full h-screen overflow-hidden">
        <div className="-mt-10 h-full flex flex-col items-center justify-center text-white overflow-hidden">
          <img src="/rafa_rocket.png" className="w-96 -ml-10" />
          <h1 className="font-bold text-4xl mt-4">
            Happy Six Months Rafa! 🚀🎉
          </h1>
          <p className="mt-4 text-xl max-w-md text-center">
            Thank you for joining us on this startup journey. We are so happy to
            have you on the team! Thank you for everything you do.
          </p>
          <p className="mt-4">
            And here is the actual Geoguessr link for today:
            <a
              className="text-primary"
              href="https://www.geoguessr.com/join/2N79?s=Url"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.geoguessr.com/join/2N79?s=Url
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
